import React from 'react';
import ChangePassword from '../../containers/ChangePassword';

export interface ChangePasswordPageProps {
	params: {
		token: string;
	};
}

const ChangePasswordPage = ({ params }: ChangePasswordPageProps) => (
	<ChangePassword token={params.token} />
);

export default ChangePasswordPage;
